<template>
  <header class="header-default">
    <p class="eyebrow" ref="eyebrowElement" v-if="data.eyebrow">{{ data.eyebrow }}</p>
    <h1 class="title" ref="titleElement" v-if="data.title">{{ data.title }}</h1>
  </header>
</template>

<script setup lang="ts">
import type { HeaderDefaultFragment } from '#gql';
import useLinesFadeIn from '~/utils/useLinesFadeIn';

const props = defineProps<{
  data: HeaderDefaultFragment;
}>();

const eyebrowElement = ref<null | HTMLElement>(null);
const titleElement = ref<null | HTMLElement>(null);

useLinesFadeIn([eyebrowElement, titleElement]);
</script>

<style scoped lang="scss">
.header-default {
  @media (--vs) {
    padding: 8.25rem var(--base-component-padding-inline-with-margins) 0
      var(--base-component-padding-inline-with-margins);
  }

  @media (--vl) {
    padding: 12.5rem var(--base-component-padding-inline-with-margins) 6.5rem
      var(--base-component-padding-inline-with-margins);
  }

  > .eyebrow {
    @include text-xl(700);

    @media (--vs) {
      padding-bottom: 1rem;
    }

    @media (--vl) {
      padding-bottom: 3rem;
    }
  }

  > .title {
    max-width: 20ch;
    hyphens: auto;
    word-break: break-word;

    @media (--vs) {
      @include text-3xl(700);
    }

    @media (--vl) {
      @include text-4xl(700);
    }
  }
}
</style>
